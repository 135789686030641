import axios from 'axios';
import { flashMessage } from 'redux-flash';
import download from 'downloadjs';

export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';
export const DOWNLOAD_REPORT_REQUEST = 'DOWNLOAD_REPORT_REQUEST';
export const DOWNLOAD_REPORT_COMPLETE = 'DOWNLOAD_REPORT_COMPLETE';
export const FETCH_ORGANISATION_DATA_SUCCESS = 'FETCH_ORGANISATION_DATA_SUCCESS';
export const FETCH_ORGANISATION_GROUP_DATA_SUCCESS = 'FETCH_ORGANISATION_GROUP_DATA_SUCCESS';
export const FETCH_PURCHASES_DATA_SUCCESS = 'FETCH_PURCHASES_DATA_SUCCESS';
export const FETCH_ALL_PURCHASES_DATA_SUCCESS = 'FETCH_ALL_PURCHASES_DATA_SUCCESS';
export const FETCH_CUSTOMER_REDEMPTION_DATA_SUCCESS = 'FETCH_CUSTOMER_REDEMPTION_DATA_SUCCESS';
export const FETCH_UNUSED_FUNDS_DATA_SUCCESS = 'FETCH_UNUSED_FUNDS_DATA_SUCCESS';
export const FETCH_REDEMPTION_DATA_SUCCESS = 'FETCH_REDEMPTION_DATA_SUCCESS';
export const FETCH_PRODUCT_DATA_SUCCESS = 'FETCH_PRODUCT_DATA_SUCCESS';
export const FETCH_INVOICE_DATA_SUCCESS = 'FETCH_INVOICE_DATA_SUCCESS';
export const FETCH_ACTIVE_INVOICE_DATA_SUCCESS = 'FETCH_ACTIVE_INVOICE_DATA_SUCCESS';
export const FETCH_TIP_DATA_SUCCESS = 'FETCH_TIP_DATA_SUCCESS';

function fetchReportRequest() {
  return {
    type: FETCH_REPORT_REQUEST
  }
}

function fetchReportFailure() {
  return {
    type: FETCH_REPORT_FAILURE
  }
}

function fetchReportSuccess() {
  return {
    type: FETCH_REPORT_SUCCESS
  }
}

function downloadReportRequest() {
  return {
    type: DOWNLOAD_REPORT_REQUEST
  }
}

function downloadReportComplete() {
  return {
    type: DOWNLOAD_REPORT_COMPLETE
  }
}

function fetchOrganisationDataSuccess(data) {
  return {
    type: FETCH_ORGANISATION_DATA_SUCCESS,
    data
  }
}

function fetchOrganisationGroupDataSuccess(data) {
  return {
    type: FETCH_ORGANISATION_GROUP_DATA_SUCCESS,
    data
  }
}

function fetchPurchasesDataSuccess(data) {
  return {
    type: FETCH_PURCHASES_DATA_SUCCESS,
    data
  }
}

function fetchAllPurchasesDataSuccess(data) {
  return {
    type: FETCH_ALL_PURCHASES_DATA_SUCCESS,
    data
  }
}

function fetchCustomerRedemptionDataSuccess(data) {
  return {
    type: FETCH_CUSTOMER_REDEMPTION_DATA_SUCCESS,
    data
  }
}

function fetchUnusedFundsDataSuccess(data) {
  return {
    type: FETCH_UNUSED_FUNDS_DATA_SUCCESS,
    data
  }
}

function fetchRedemptionDataSuccess(data) {
  return {
    type: FETCH_REDEMPTION_DATA_SUCCESS,
    data
  }
}

function fetchProductDataSuccess(data) {
  return {
    type: FETCH_PRODUCT_DATA_SUCCESS,
    data
  }
}

function fetchInvoiceDataSuccess(data) {
  return {
    type: FETCH_INVOICE_DATA_SUCCESS,
    data
  }
}

function fetchActiveInvoiceDataSuccess(data) {
  return {
    type: FETCH_ACTIVE_INVOICE_DATA_SUCCESS,
    data
  }
}

function fetchTipDataSuccess(data) {
  return {
    type: FETCH_TIP_DATA_SUCCESS,
    data
  }
}

export function fetchOrganisationReportData(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/organisation', {params: data})
      .then(response => {
        dispatch(fetchOrganisationDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching report data. Please check dates and try again', {isError: true}));
      });
  }
}

export function fetchOrganisationReportGroupData(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/organisation/group', {params: data})
      .then(response => {
        dispatch(fetchOrganisationGroupDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching group data. Please try again later', {isError: true}));
      });
  }
}

export function downloadOrganisationReportData(data) {
  return(dispatch) => {
    return axios.get('reports/organisation.xlsx', {params: data, responseType: 'blob'})
      .then(response => {
        download(response.data, 'organisation_report.xlsx');
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
      });
  }
}

export function fetchCompanyReportData(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/company', {params: data})
      .then(response => {
        dispatch(fetchOrganisationDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching report data. Please check dates and try again', {isError: true}));
      });
  }
}

export function fetchCompanyOrganisationReportData(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/company/organisation', {params: data})
      .then(response => {
        dispatch(fetchOrganisationGroupDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching group data. Please try again later', {isError: true}));
      });
  }
}

export function downloadCompanyReportData(data) {
  return(dispatch) => {
    return axios.get('reports/company.xlsx', {params: data, responseType: 'blob'})
      .then(response => {
        download(response.data, 'full_organisation_report.xlsx');
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
      });
  }
}

export function fetchPurchasesReportData(data, route, page) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/' + route + '?page=' + page, {params: data})
      .then(response => {
        if (route === 'purchases') {
          dispatch(fetchPurchasesDataSuccess(response.data.data));
        } else {
          dispatch(fetchAllPurchasesDataSuccess(response.data.data));
        }
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching report data. Please check dates and try again', {isError: true}));
      });
  }
}

export function downloadPurchasesReportData(data, route, viaEmail) {
  return(dispatch) => {
    dispatch(downloadReportRequest());
    return axios.get('reports/' + route + '.xlsx', {params: Object.assign(data, {via_email: viaEmail}), responseType: viaEmail ? 'json' : 'blob'})
      .then(response => {
        if (viaEmail) {
          dispatch(flashMessage('Report has been successfully requested and will be delivered to your email shortly'));
          window.parent.postMessage('scroll', '*');
        } else {
          download(response.data, route + '_report.xlsx');
        }
        dispatch(downloadReportComplete());
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
        dispatch(downloadReportComplete());
      });
  }
}

export function downloadDailyUserReport() {
  return(dispatch) => {
    dispatch(downloadReportRequest());
    return axios.get('reports/daily_user_purchases.pdf')
      .then(response => {
        download(response.data, 'daily_user_report_' + (new Date()).toISOString().split('T')[0] + '.pdf');
        dispatch(downloadReportComplete());
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
        dispatch(downloadReportComplete());
      });
  }
}

export function fetchCustomerRedemptionReportData(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/customer_redemptions', {params: data})
      .then(response => {
        dispatch(fetchCustomerRedemptionDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching report data. Please check dates and try again', {isError: true}));
      });
  }
}

export function downloadCustomerRedemptionReportData(data, viaEmail) {
  return(dispatch) => {
    dispatch(downloadReportRequest());
    return axios.get('reports/customer_redemptions.xlsx', {params: Object.assign(data, {via_email: viaEmail}), responseType: viaEmail ? 'json' : 'blob'})
      .then(response => {
        if (viaEmail) {
          dispatch(flashMessage('Report has been successfully requested and will be delivered to your email shortly'));
          window.parent.postMessage('scroll', '*');
        } else {
          download(response.data, 'redemption_report.xlsx');
        }
        dispatch(downloadReportComplete());
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
        dispatch(downloadReportComplete());
      });
  }
}

export function fetchUnusedFundsReportData(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/unused_funds', {params: data})
      .then(response => {
        dispatch(fetchUnusedFundsDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching report data', {isError: true}));
      });
  }
}

export function downloadUnusedFundsReportData(data) {
  return(dispatch) => {
    return axios.get('reports/unused_funds.xlsx', {params: data, responseType: 'blob'})
      .then(response => {
        download(response.data, 'unused_funds_report.xlsx');
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
      });
  }
}

export function fetchRedemptionReportData(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/redemptions', {params: data})
      .then(response => {
        dispatch(fetchRedemptionDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching report data. Please check dates and try again', {isError: true}));
      });
  }
}

export function downloadRedemptionReportData(data) {
  return(dispatch) => {
    return axios.get('reports/redemptions.xlsx', {params: data, responseType: 'blob'})
      .then(response => {
        download(response.data, 'redemption_report.xlsx');
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
      });
  }
}

export function fetchProductReportData(data, page) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/products?page=' + page, {params: data})
      .then(response => {
        dispatch(fetchProductDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching report data. Please check dates and try again', {isError: true}));
      });
  }
}

export function downloadProductReportData(data) {
  return(dispatch) => {
    return axios.get('reports/products.xlsx', {params: data, responseType: 'blob'})
      .then(response => {
        download(response.data, 'product_report.xlsx');
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
      });
  }
}

export function fetchInvoiceReportData(data, page, perPage, formRef) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/invoices/' + data.invoice_id + '?page=' + page + '&per_page=' + perPage)
      .then(response => {
        dispatch(fetchInvoiceDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        if (error.response.data.error) {
          formRef.updateInputsWithError({invoice_id: error.response.data.error})
        }
      });
  }
}

export function fetchActiveInvoiceReportData(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/invoices', {params: data})
      .then(response => {
        dispatch(fetchActiveInvoiceDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
      });
  }
}

export function requestWebstoresReport(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/webstores', {params: data})
      .then(response => {
        dispatch(fetchReportSuccess());
        dispatch(flashMessage('Report has been requested and will arrive via email shortly'));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage(error.response.data.error, {isError: true}));
      });
  }
}

export function downloadInvoiceReportData(data) {
  return(dispatch) => {
    return axios.get('reports/invoices/' + data.invoice_id + '.xlsx', {responseType: 'blob'})
      .then(response => {
        download(response.data, 'invoice_summary_report.xlsx');
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
      });
  }
}

export function downloadActiveInvoiceReportData(data) {
  return(dispatch) => {
    return axios.get('reports/invoices.xlsx', {params: data, responseType: 'blob'})
      .then(response => {
        download(response.data, 'active_invoice_summary_report.xlsx');
      }).catch(error => {
        dispatch(flashMessage('Error downloading report', {isError: true}));
      });
  }
}

export function fetchTipReportData(data) {
  return(dispatch) => {
    dispatch(fetchReportRequest());
    return axios.get('reports/tips_summary', {params: data})
      .then(response => {
        dispatch(fetchTipDataSuccess(response.data.data));
      }).catch(error => {
        dispatch(fetchReportFailure());
        dispatch(flashMessage('Error fetching report data. Please check dates and try again', {isError: true}));
      });
  }
}
